import React from 'react';
import { Outlet } from "react-router-dom";
import logo from '../assets/img/header.png';

import { useEffect } from 'react';

import { scrollToHashElement } from '../helpers/scrollToHashElement';
import { useHashFragment } from '../hooks/useHashFragment';

function Layout1() 
{
    useEffect(() => {
        scrollToHashElement(150);
    }, []);
    useHashFragment(150);
   
    return (
        <div className="page-wrap">
            <header className="header">
                <section className="nav-section">
                    <div className="container">
                        <a className="brand-link" href="/"><img src={logo} />Hamick Services</a>
                        <nav className="main-nav">
                            <ul>
                                <li className="nav-item"><a href="/home#our-process">Our Process</a></li>
                                <li className="nav-item"><a href="/home#work-testimonials">Work &amp; Testimonials</a></li>
                                <li className="nav-item"><a href="/home#contact-us">Contact Us</a></li>
                                <li className="nav-item mobile-hidden">
                                    <p>Articles</p>
                                    <ul className='sub-menu'>
                                        <li className='sub-menu-nav-item'><a href="/see-the-light">See the Light</a></li>
                                        <li className='sub-menu-nav-item'><a href="/top-teir-tiles">Top teir Tiles</a></li>
                                        <li className='sub-menu-nav-item'><a href="/how-much-do-you-bench">How much do you Bench?</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item"><a href="/home#faqs">FAQs</a></li>
                            </ul>
                        </nav>
                    </div>
                </section>
            </header>

            <main>
                <Outlet />
            </main>

            <div className="footer">
                <footer className="py-5 bg-dark">
                    <div className="container">
                        <p className="m-0 text-center text-white">
                            Copyright &copy; HamickConstruction 2023
                        </p>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default Layout1;
import { useEffect } from "react";
import { useEventListener } from "./useEventListener.js";
import { scrollToHashElement } from "../helpers/scrollToHashElement.js";

export const useHashFragment = (offset = 0, trigger = true) =>
    useEventListener(
        window,
        "hashchange",
        () => (trigger ? scrollToHashElement(offset) : undefined),
        [offset, trigger]
    );
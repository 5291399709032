import imageA from '../../assets/img/Triangle/After/700-AD2387-Waitakere-City.jpg';
import imageB from '../../assets/img/762542373dcfa29d2729598d0db39206910f62c5.jpg';
import imageC from '../../assets/img/Clyma House/After Photos/43c3adaa2ca9cebe59e9b5c39f5467db120d3f07.jpg';
import imageD from '../../assets/img/Triangle/After/186-AD2387-Waitakere-City.jpg';
import imageE from '../../assets/img/b9f0-H2362952-hires.8423-4.jpg';

function WorkAndTestimonials() {
    return (
        <section className="work-testimonials">
            <div className="container">
                <h2>Work &amp; Testimonials</h2>
                <p>
                    Here are some of our recent work with a few comments from the client. 
                    Some projects are big, some are small, and each project requires attention to detail to make it a long lasting and suitable improvement. 
                    We can organise plumbing, electrical, building, kitchen and bathroom design plus more. 
                    If you're unsure what you need, just reach out. As simple as that.
                </p><br/>
                <div className="content-wrap">
                    <div className="grid">
                        <div className="content flow">
                            <h3 className="title">Kitchens</h3>
                            <div className="image">
                                <img alt="Repair stuff" src={imageA} />
                            </div>
                        </div>
                        <div className="content flow">
                            <h3 className="title">Bathrooms</h3>
                            <div className="image">
                                <img alt="Repair stuff" src={imageB}/>
                            </div>
                        </div>
                        <div className="content flow">
                            <h3 className="title">Home Office</h3>
                            <div className="image">
                                <img alt="Repair stuff" src={imageC}/>
                            </div>
                        </div>
                        <div className="content flow">
                            <h3 className="title">Bedrooms</h3>
                            <div className="image">
                                <img alt="Repair stuff" src={imageD}/>
                            </div>
                        </div>
                        <div className="content flow">
                            <h3 className="title">Outdoors</h3>
                            <div className="image">
                                <img alt="Repair stuff" src={imageE}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WorkAndTestimonials;
import React, { useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import contactUsGloryshot from '../../assets/img/Triangle/After/700-AD2387-Waitakere-City.jpg';

function ContactUs() {
    const api = `/api`
    const recaptchaSiteKey = '6LdB0zYgAAAAAIfK5bRH1D5lp59SW6zPHru81JqD'

    //
    const [mailVals, setMailVals] = useState([]);
    const [mailSent, setMailSent] = useState(false);
    const recaptchaRef = useRef(null);

    // 
    const handleMailDataChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setMailVals(values => ({...values, [name]: value}));
    }

    //
    const handleSubmit = (event) => {
        event.preventDefault();

        const recaptchaValue = recaptchaRef.current.getValue();
        if ("" === recaptchaValue) {
            alert('Please let us know that you are now a Bot');
            return;
        }
        recaptchaRef.current.reset();

        setMailSent(true);

        const data = [mailVals, {r: recaptchaValue}];
        axios.post(`${api}/send_mail`, data).then(function(response){
            console.log('message sent');
        });
    }

    return (
        <section className="contact-us">
            <div className="container">
                <h2>Contact Us</h2>
                <div className="contact-table contact-form">
                    <div className="contact-row">
                        <div className="column-a">
                            {mailSent  ? (
                                <div className="email-sent">
                                    Thank you for contacting us.
                                </div>
                            ) : (
                            <form method="POST" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Your Name</label>
                                    <input className="form-control item" type="text" id="name" name="name" onChange={handleMailDataChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input className="form-control item" type="email" id="email" name="email" onChange={handleMailDataChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea className="form-control item" id="message" name="message" onChange={handleMailDataChange}></textarea>
                                </div>
                                <div className="form-group captcha-row">
                                    <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaSiteKey} />
                                </div>
                                <div className="form-group button-row">
                                    <button className="btn btn-primary btn-block btn-lg submit-button" type="submit">Send Message</button>
                                </div>
                            </form>
                            )}
                        </div>
                        <div className="column-b">
                            <div className="contact-gloryshot">
                                <img src={contactUsGloryshot} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-table bullet-points">
                    <div className="contact-row">
                        <div className="contact-address">
                            <div className="icon">
                                <span className="fa fa-map-marker"></span>
                            </div>
                            <label>Address</label>
                            <p>Auckland-wide</p>
                        </div>
                        <div className="contact-phone">
                            <div className="icon">
                                <span className="fa fa-phone"></span>
                            </div>
                            <label>Phone</label>
                            <p>022-633-2335</p>
                        </div>
                        <div className="contact-email">
                            <div className="icon">
                                <span className="fa fa-paper-plane"></span>
                            </div>
                            <label>Email</label>
                            <p><a href="mailto:info@hamickservices.nz">info@hamickservices.nz</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUs;
import processBG from '../../assets/img/our-process-bg.png';

function OurProcess() {
    return (
        <section className="our-process">
            <div className="container">
                <div className="content-wrap">
                    <h2>Our Process couldn't be simpler</h2>
                    <p>
                        We pride ourselves on keeping things as simple as we can. Here's how we do it;<br/><br/>
                        <strong>1)</strong> A time is arranged with you to understand your requirements.<br/>
                        <strong>2)</strong> Options are provided with advice and we provide a quote. <br/>
                        <strong>3)</strong> Your job is completed as scheduled.<br/><br/>
                        <div className="text-block">
                            We are here to offer solutions and improvements around your home. 
                            You also don't have to hire a separate person for each aspect of the job as that's all organised with us, 
                            so you can focus on communicating with one person. Easy!
                        </div>
                    </p>
                    <div className="info-graphic">
                        <div className="our-process-info-graphic-content">
                            <img src={processBG} className="our-process-info-graphic-bg" />
                            <div className="point point-1">
                                <h3>SPEC</h3>
                                <p>Work planned out</p>
                            </div>
                            <div className="point point-2">
                                <h3>WORK</h3>
                                <p>People and materials arranged</p>
                            </div>
                            <div className="point point-3">
                                <h3>COMPLETE</h3>
                                <p>Completed and signed off</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurProcess;
import React, {useState} from "react";

function Faqs() {

    const [activeFAQ, setActiveFAQ] = useState(0);

    const inActiveRowClassName = "row toggle-able-rows";
    const activeRowClassName = inActiveRowClassName + " active-row";

    function onClicked(id) {
        setActiveFAQ(id);
    }

    return (
        <section className="faqs">
            <div className="container">
                <h2>FAQs</h2>
                <div className="content-wrap">
                    <div className="grid">
                        <div className={activeFAQ === 0 ? activeRowClassName : inActiveRowClassName} onClick={() => {onClicked(0);}}>
                            <h3 className="faq-question">Q. If a piece of wood furniture is varnished, can I just paint right over it?</h3>
                            <div className="faq-answer">A. No. You will have to remove the furniture’s coating first or the paint will bubble and peel</div>
                        </div>
                        <div className={activeFAQ === 1 ? activeRowClassName : inActiveRowClassName} onClick={() => {onClicked(1);}}>
                            <h3 className="faq-question">Q. Do I really need to find a stud when I’m hanging something on a wall?</h3>
                            <div className="faq-answer">A. If what you’re hanging weighs more than 34kg, yes, it is recommended you use a stud. Vertical studs are normally spaced 450mm or 600mm apart</div>
                        </div>
                        <div className={activeFAQ === 2 ? activeRowClassName : inActiveRowClassName} onClick={() => {onClicked(2);}}>
                            <h3 className="faq-question">Q. I want to replace a ceiling fan with a light fixture. Will I have to install all-new wiring?</h3>
                            <div className="faq-answer">A. The wiring is exactly the same, and the swap is simple, so you can do it yourself if you feel comfortable. </div>
                        </div>
                        <div className={activeFAQ === 3 ? activeRowClassName : inActiveRowClassName} onClick={() => {onClicked(3);}}>
                            <h3 className="faq-question">Q. What’s the difference between mold and mildew?</h3>
                            <div className="faq-answer">A. Both are fungi that thrive in moist environments, such as in bathrooms and on windowsills. Mildew refers to mold that’s growing flat in its early stages, like on a shower curtain liner.</div>
                        </div>
                        <div className={activeFAQ === 4 ? activeRowClassName : inActiveRowClassName} onClick={() => {onClicked(4);}}>
                            <h3 className="faq-question">Q. Is engineered hardwood real wood or fake wood?</h3>
                            <div className="faq-answer">A. It’s real wood, but it’s not solid wood. Think of engineered wood as a multilayered wood sandwich.</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faqs;
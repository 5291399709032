import React from "react";
import { Slide } from "react-slideshow-image";

import image1 from '../../assets/img/Clyma House/After Photos/235e35960f8206ec422c34df832273afb45d3f25.jpg';
import image2 from '../../assets/img/Clyma House/After Photos/6cc0-H2362952-hires.8509-7-Copy.jpg';
import image3 from '../../assets/img/Clyma House/After Photos/4f80-H2362952-hires.23373-6.jpg';
import image4 from '../../assets/img/762542373dcfa29d2729598d0db39206910f62c5.jpg';

import "react-slideshow-image/dist/styles.css";
import styles from "../../assets/css/slideshow.css";

function HomeSlideshow() {
    const slideProperties = {
        duration: 8000,
        transitionDuration: 3000,
        infinite: true,
        indicators: false,
        arrows: false
    };

    return (
        <div className="slideshow-wrapper">
            <Slide {...slideProperties}>
                <img style={styles.slide} src={image1} />
                <img style={styles.slide} src={image2} />
                <img style={styles.slide} src={image3} />
                <img style={styles.slide} src={image4} />
            </Slide>
        </div>
    )
}

export default HomeSlideshow;
import React from "react";
import { render } from 'react-dom';
import { BrowserRouter as Router, Navigate, Routes, Route } from "react-router-dom";

import Layout1 from "./layouts/Layout1"
import Layout2 from "./layouts/Layout2"
import ErrorLayout from "./layouts/ErrorLayout"

import Home from "./pages/Home"
import PERT from "./tools/PERT"

import Article1 from "./pages/Article1"
import Article2 from "./pages/Article2"
import Article3 from "./pages/Article3"

// ERROR PAGES -------------------------------------------------------
import Error404 from "./errors/Error404"
import Error500 from "./errors/Error500"

// CSS Imports -------------------------------------------------------
import "./index.css";

export default function App() {
	return (
	  	<Router>
			<Routes>
		  		<Route path="/" element={<Layout1 />}>
					<Route index element={<Home />} />
					<Route path="/see-the-light" element={<Article1 />} />
					<Route path="/top-teir-tiles" element={<Article2 />} />
					<Route path="/how-much-do-you-bench" element={<Article3 />} />
					<Route path="*" element={<Home />} />
		  		</Route>
		  		<Route path="/tools" element={<Layout2 />}>
					<Route index element={<PERT />} />
		  		</Route>
				<Route element={<ErrorLayout />}>
					<Route path="errors/404" element={<Error404 />} />
					<Route path="errors/500" element={<Error500 />} />
				</Route>
        		<Route path="*" element={<Navigate to="/errors/404" replace />} />
			</Routes>
	  	</Router>
	);
}
  
render(<App />, document.getElementById("root"));
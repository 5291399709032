import React from 'react';

import HomeSlideshow        from '../components/homepage/slideshow'
import OurProcess           from '../components/homepage/our-process'
import WorkAndTestimonials  from '../components/homepage/work-and-testimonials'
import ContactUs            from '../components/homepage/contact-us'
import Faqs                 from '../components/homepage/faqs'

function Home() {
  	return (
		<>
			<HomeSlideshow />
			<OurProcess />
			<WorkAndTestimonials />
			<ContactUs />
			<Faqs />
		</>
	);
}

export default Home;
import React from "react";

function Article1() {
    return (
        <section className="article">
            <div className="container">
                <div className="content-wrap">
					<br/>
                    <h2>How much do you Bench?</h2>
                    <p>
						New bench-tops can dramatically improve the look and feeling of your kitchen. They can be the focal point of the room and can 
						change the overall aesthetic of the space. The options for bench-tops are endless and can range from traditional materials 
						like granite and marble to more modern options like concrete and engineered stone.<br/><br/>

						Granite is a popular choice for kitchen bench-tops as it is durable and resistant to heat, scratches and stains. It is also a 
						natural stone that comes in a variety of colours and patterns. Marble is another natural stone that can add a touch of luxury to 
						a kitchen. It is also heat resistant and easy to clean but it is less durable than granite and can be prone to scratches and staining.<br/><br/>

						Concrete and engineered stone are modern alternatives to traditional bench-tops. Concrete is a versatile material that can be 
						customized to match any kitchen design. It is also durable and heat resistant. Engineered stone is a man-made product that is 
						made from a mix of natural stones and resins. It is also heat resistant, durable and low maintenance.<br/><br/>

						Another option for bench-tops is laminate. Laminate is a cost-effective option that comes in a wide range of colours and 
						patterns. It is also easy to clean and maintain. However, it is not as durable as natural stone or engineered stone.<br/><br/>

						When choosing a new bench-top for your kitchen, it is important to consider the overall style of your kitchen, the level of 
						maintenance you are willing to undertake, and your budget. A new bench-top can be a great investment that will not only improve 
						the look of your kitchen but also increase the value of your home.<br/><br/>

						In summary, new bench-tops can be a great way to update the look and feel of your kitchen. They can range from traditional 
						materials like granite and marble to modern options like concrete and engineered stone. It's important to consider the overall style of 
						your kitchen, the level of maintenance you are willing to undertake, and your budget when choosing a new bench-top. A new 
						bench-top can be a great investment that will not only improve the look of your kitchen but also increase the value of your home.
						<br/><br/>
						<br/><br/>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Article1;
import { getHashElement } from "./getHashElement";

/** @param {number} offset */
export const scrollToHashElement = offset => {
    const elementToScroll = getHashElement(".");
    console.log(elementToScroll)

    return elementToScroll
        ? window.scrollTo({
                top: elementToScroll.offsetTop - offset,
                behavior: "smooth"
        })
        : undefined;
};
import React from "react";

function Article1() {
    return (
        <section className="article">
            <div className="container">
                <div className="content-wrap">
					<br/>
                    <h2>Top teir Tiles</h2>
                    <p>
						New tiling is a great way to quickly raise the value of your home without breaking the bank. Tiling is a cost-effective 
						and easy way to update the look and feel of your home, and it can be done in a relatively short amount of time.<br/><br/>

						One of the biggest advantages of new tiling is that it can be done on a budget. Tiles come in a wide range of prices, so 
						you can choose the option that best fits your budget. Additionally, tiling is a relatively easy DIY project, so you can 
						save even more money by doing the work yourself.<br/><br/>

						Another advantage of new tiling is that it can be done in a variety of rooms in your home. Tiling can be used in bathrooms, 
						kitchens, and even living rooms to add a touch of elegance and sophistication. Tiling is also a great way to update old or 
						outdated flooring, making your home feel new again.<br/><br/>

						New tiling can also help to increase the overall value of your home. A well-tiled home can be more appealing to potential 
						buyers and can help to increase the overall value of your home. This is especially true if the tile is high-quality and 
						installed correctly.<br/><br/>

						In addition to raising the value of your home, new tiling can also improve the functionality of your home. Tiles are 
						durable and easy to clean, making them perfect for high-traffic areas in your home. They are also water-resistant, making 
						them ideal for bathrooms and kitchens.<br/><br/>

						Overall, new tiling is a great way to quickly raise the value of your home. It is cost-effective, easy to install, and can 
						be done in a variety of rooms in your home. It is also a great way to update old or outdated flooring and improve the 
						functionality of your home.
						<br/><br/>
						<br/><br/>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Article1;